@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
}

body {
  box-sizing: border-box;
  background-color: rgb(249, 247, 246);
}

.sr-only {
  display: none;
}

.leaflet-marker-draggable, .leaflet-touch-icon {
  height: 8px !important;
  width: 8px !important;
  margin-left: -5px !important;
  margin-top: -6px !important
}
